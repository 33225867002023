var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "customdirective" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Custom Directives")]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        "Using Vue custom directive attributes to create custom functionality."
      )
    ]),
    _c(
      "p",
      {
        directives: [
          {
            name: "fancy",
            rawName: "v-fancy.delayed",
            value: { className: "fancyBackground", delay: 3000 },
            expression: "{ className: 'fancyBackground', delay: 3000 }",
            modifiers: { delayed: true }
          }
        ],
        staticClass: "paragraph"
      },
      [
        _vm._v(
          "Using a Vue plugin to set a custom directive on this paragraph."
        )
      ]
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "highlight",
            rawName: "v-highlight",
            value: { color: "red" },
            expression: "{ color: 'red' }"
          }
        ],
        staticClass: "paragraph customdirective__body"
      },
      [_vm._v(" Simple example with one object value. ")]
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "highlight",
            rawName: "v-highlight:background",
            value: { color: "deeppink", backgroundColor: "deepskyblue" },
            expression: "{ color: 'deeppink', backgroundColor: 'deepskyblue' }",
            arg: "background"
          }
        ],
        staticClass: "paragraph customdirective__body"
      },
      [
        _vm._v(
          " Example directive with a directive argument 'background' and multiple object values. "
        )
      ]
    ),
    _c(
      "p",
      {
        directives: [
          {
            name: "highlight",
            rawName: "v-highlight:background.delayed",
            value: {
              color: "white",
              backgroundColor: "deeppink",
              delay: 2000,
              classname: "has-hightlight"
            },
            expression:
              "{ color: 'white', backgroundColor: 'deeppink', delay: 2000, classname: 'has-hightlight' }",
            arg: "background",
            modifiers: { delayed: true }
          }
        ],
        staticClass: "paragraph customdirective__body"
      },
      [
        _vm._v(
          " Example with a directive argument and a directive modifier. And also adding a classname to the element. "
        )
      ]
    ),
    _c("p", { staticClass: "paragraph customdirective__body" }, [
      _vm._v("Directives can be set globally or localy.")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }